const light = Object.freeze({
    primary: '#961e28',
    secondary: '#BC8085',
    navBackground: '#FFFFFF',
    tileBackground: '#FFFFFF',
    appBackground: '#F5F5F5',
    inputBackground: '#FFFFFF',
    logoColor: '#961e28',
    logoFontColor: '#1B1B1B',
    error: '#961E28',
    info: '#4E87A0',
    success: '#808C24',
    warning: '#DAAA00',
})

const dark = Object.freeze({
    primary: '#98B5C1',
    secondary: '#BC8085',
    navBackground: '#2C2C2C',
    tileBackground: '#2C2C2C',
    appBackground: '#1B1B1B',
    inputBackground: '#222222',
    logoColor: '#FFFFFF',
    logoFontColor: '#FFFFFF',
    error: '#BC8085',
    info: '#98B5C1',
    success: '#BAC08C',
    warning: '#DEC671',
})

const props = Object.freeze({
    headerLogoWidth: 170,
    headerLogoSrc: require('@/assets/custom/images/headers/living-header.jpg')
})

module.exports = Object.freeze({
    light,
    dark,
    props
})

