<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 215">
    <g :fill="fillColor">
      <polygon class="cls-1" points="155.51 115.81 158.14 123.08 155.51 115.81 155.51 115.81"/>
      <rect class="cls-1" x="159.82" y="127.73" width="14.74" height="0"/>
      <path class="cls-1" d="M139.94,87.82c.54-1.63,1-3.13,1.38-4.44-.38,1.31-.84,2.81-1.38,4.44Z"/>
      <path class="cls-1" d="M142.78,78.89h0s-.19,0-.19,0c-.07.24-.16.54-.27.91.1-.37.19-.67.27-.91h.19Z"/>
      <path class="cls-1" d="M141.31,83.38c.25-.86.47-1.63.65-2.31-.19.67-.41,1.45-.65,2.31Z"/>
      <polygon class="cls-1" points="136.56 64.2 129.04 83.85 136.56 64.2 136.56 64.2"/>
      <path class="cls-1" d="M142.12,80.52c.07-.26.14-.5.2-.72-.06.22-.13.46-.2.72Z"/>
      <path class="cls-1" d="M139.73,88.44c.07-.21.14-.41.2-.61-.07.2-.13.41-.2.61Z"/>
      <polygon class="cls-1"
               points="174.56 127.73 174.56 127.73 149.58 64.2 136.56 64.2 136.56 64.2 149.58 64.2 174.56 127.73"/>
      <polygon class="cls-1" points="128.26 120.88 130.05 115.81 130.05 115.81 128.26 120.88"/>
      <polygon class="cls-1" points="159.82 127.73 159.82 127.73 158.14 123.08 159.82 127.73"/>
      <rect class="cls-1" x="111.03" y="125.96" width="3.79" height="0"
            transform="translate(-45.07 186.4) rotate(-69.06)"/>
      <polygon class="cls-1" points="129.04 83.85 115.93 118.11 115.93 118.11 129.04 83.85"/>
      <path class="cls-1"
            d="M151.69,105.03l-5.46-15.17c-.5-1.36-.93-2.64-1.31-3.81.37,1.17.8,2.45,1.31,3.81l5.46,15.17Z"/>
      <path class="cls-1" d="M144.07,83.23c.11.36.22.75.34,1.15-.12-.4-.23-.78-.34-1.15Z"/>
      <path class="cls-1" d="M144.53,84.78c.08.27.17.55.26.84-.09-.29-.18-.57-.26-.84Z"/>
      <polygon class="cls-1" points="139.24 89.86 133.78 105.03 133.78 105.03 139.24 89.86"/>
      <path class="cls-1" d="M143.87,82.53c.06.23.13.46.2.71-.07-.24-.14-.48-.2-.71Z"/>
      <path class="cls-1" d="M143.21,80.2c.09.32.19.67.3,1.06-.11-.39-.21-.74-.3-1.06Z"/>
      <path class="cls-1" d="M142.78,78.89h0c.13.31.26.75.43,1.31-.16-.55-.3-1-.43-1.31Z"/>
      <path class="cls-1" d="M143.6,81.56c.09.3.18.63.27.97-.1-.34-.19-.66-.27-.97Z"/>
      <rect class="cls-1" x="111.51" y="121.15" width="6.5" height="0"
            transform="translate(-39.4 185.03) rotate(-69.06)"/>
      <path class="cls-1"
            d="M115.93,107.01v-14.58h-24.7v10.4h11.77v12.59c-2.2,1.43-6.13,2.48-11.3,2.48-13.4,0-19.24-10.21-19.24-22.42s6.51-21.27,20.1-21.27c6.6,0,11.3,1.72,15.22,4.58l6.03-10.02c-4.98-3.62-11.39-5.72-20.96-5.72-22.11,0-34.75,15.55-34.75,32.81s11.77,33,33.69,33c7.06,0,12.49-1.01,16.7-2.46l.04-.1,7.39-19.31Z"/>
      <path class="cls-1"
            d="M149.58,64.2h-13.02l-7.52,19.65-13.11,34.26-2.32,6.07-1.36,3.54h0s13.59,0,13.59,0l2.42-6.85,1.79-5.07h25.46l2.63,7.27,1.68,4.65h14.74l-24.98-63.53ZM133.78,105.03l5.46-15.17c.17-.48.33-.96.49-1.42.07-.21.14-.41.2-.61.54-1.63,1-3.13,1.38-4.44.25-.86.47-1.63.65-2.31.05-.19.1-.38.15-.55.07-.26.14-.5.2-.72.1-.37.19-.67.27-.91h.19c.13.31.26.75.43,1.31.09.32.19.67.3,1.06.03.1.06.2.08.3.09.3.18.63.27.97.06.23.13.46.2.71.11.36.22.75.34,1.15.04.13.08.27.12.4.08.27.17.55.26.84.04.14.09.29.14.43.37,1.17.8,2.45,1.31,3.81l5.46,15.17h-17.9Z"/>
      <path class="cls-1"
            d="M202.53,92.44v10.4h11.77v12.59c-2.2,1.43-6.13,2.48-11.3,2.48-13.4,0-19.24-10.21-19.24-22.42s6.51-21.27,20.1-21.27c6.6,0,11.3,1.72,15.22,4.58l6.03-10.02c-4.98-3.62-11.39-5.72-20.96-5.72-22.11,0-34.75,15.55-34.75,32.81s11.77,33,33.69,33c12.25,0,19.62-3.05,24.12-6.1v-30.33h-24.7Z"/>
      <path class="cls-1" d="M63,136.65h2.26v19.26h-2.26v-19.26Z"/>
      <path class="cls-1"
            d="M68.9,142.03h2.12v1.97c.61-.75,1.89-2.31,4.27-2.31,2.03,0,3.11,1.04,3.57,2.34,1.07-1.19,2.47-2.34,4.5-2.34,2.82,0,3.92,1.88,3.92,4.34v9.89h-2.12v-9.49c0-1.68-.52-2.83-2.29-2.83s-3.05,1.33-3.72,2.17v10.15h-2.12v-9.49c0-1.74-.61-2.83-2.23-2.83-2.03,0-3.48,2.11-3.77,2.52v9.81h-2.12v-13.88Z"/>
      <path class="cls-1"
            d="M90.94,142.03h2.12v1.97c.61-.75,1.89-2.31,4.27-2.31,2.03,0,3.11,1.04,3.57,2.34,1.07-1.19,2.47-2.34,4.5-2.34,2.82,0,3.92,1.88,3.92,4.34v9.89h-2.12v-9.49c0-1.68-.52-2.83-2.29-2.83s-3.05,1.33-3.72,2.17v10.15h-2.12v-9.49c0-1.74-.61-2.83-2.23-2.83-2.03,0-3.48,2.11-3.77,2.52v9.81h-2.12v-13.88Z"/>
      <path class="cls-1"
            d="M143.29,137.87c0-.87.7-1.56,1.57-1.56s1.57.69,1.57,1.56-.7,1.56-1.57,1.56-1.57-.69-1.57-1.56ZM143.78,142.03h2.15v13.88h-2.15v-13.88Z"/>
      <path class="cls-1" d="M150.38,135.09h2.12v20.83h-2.12v-20.83Z"/>
      <path class="cls-1"
            d="M156.29,137.87c0-.87.7-1.56,1.57-1.56s1.57.69,1.57,1.56-.7,1.56-1.57,1.56-1.57-.69-1.57-1.56ZM156.79,142.03h2.15v13.88h-2.15v-13.88Z"/>
      <path class="cls-1"
            d="M176.35,142.03h2.12v2c1.25-1.56,2.82-2.34,4.53-2.34,2.93,0,4.09,1.94,4.09,4.34v9.89h-2.12v-9.49c0-1.74-.61-2.83-2.47-2.83-1.57,0-3.05,1.19-4.03,2.55v9.78h-2.12v-13.88Z"/>
      <path class="cls-1"
            d="M200.83,136.65h2.21l7.43,19.26h-2.47l-1.77-4.77h-8.79l-1.77,4.77h-2.23l7.4-19.26ZM205.51,149.23l-2.44-6.59c-.61-1.62-.87-2.43-1.19-3.33h-.06c-.32.95-.64,1.85-1.19,3.33l-2.47,6.59h7.34Z"/>
      <path class="cls-1"
            d="M161.79,148.89c0-4.08,2.55-7.2,6.27-7.2,3.45,0,5.49,2.46,5.49,6.25,0,.32-.03.64-.06.98h-9.43c.06,2.95,1.25,5.52,4.38,5.52,1.94,0,3.25-.87,3.86-1.24l.87,1.65c-.7.46-2.26,1.42-4.79,1.42-4.38,0-6.59-3.27-6.59-7.38ZM171.37,147.24c-.06-1.97-.96-3.73-3.37-3.73-2.23,0-3.45,1.56-3.83,3.73h7.2Z"/>
      <path class="cls-1"
            d="M112.54,149c0-3.24,1.65-7.32,6.36-7.32s6.65,4.02,6.65,7.26-1.86,7.32-6.56,7.32-6.44-4.02-6.44-7.26ZM123.28,149c0-2.98-1.51-5.5-4.41-5.5s-4.06,2.46-4.06,5.44,1.31,5.5,4.21,5.5,4.27-2.46,4.27-5.44Z"/>
      <path class="cls-1"
            d="M128.8,155.19v-20.1h2.12v8.04c.81-.84,2.15-1.45,3.48-1.45,3.8,0,6.01,2.92,6.01,7.23s-2.96,7.35-7.02,7.35c-1.92,0-3.66-.49-4.59-1.07ZM138.15,148.97c0-2.92-1.13-5.38-4.15-5.38-1.16,0-2.44.67-3.08,1.36v9.05c.55.26,1.42.43,2.5.43,3.16,0,4.73-2.66,4.73-5.47Z"/>
      <path class="cls-1"
            d="M210.01,146.23c0-5.24,3.54-9.92,9.72-9.92,2.55,0,4.53.61,6.1,1.82l-1.19,1.65c-1.51-1.19-2.93-1.59-4.96-1.59-5.05,0-7.29,3.85-7.29,7.98s2.26,8.19,7.29,8.19c2.12,0,3.8-.46,4.79-1.19v-5.41h-4.15v-1.91h6.41v8.42c-1.45,1.21-3.95,2-7.11,2-6.12,0-9.61-4.89-9.61-10.04Z"/>
      <path class="cls-1"
            d="M340.85,133.64c-.75-1.11-3.28-1.9-6.03-2.45h0c-3.3-.57-6.62-.97-9.95-1.22v-22.69c0-.98-.46-1.9-1.25-2.48l-25.24-18.72c-1.23-.92-2.95-.79-4.04.29l-5.97,5.9-8.36-31.85c-.36-1.36-1.59-2.31-3-2.31h0c-1.41,0-2.64.95-3,2.3l-6.96,26.22-6.44-21.36c-.42-1.39-1.74-2.31-3.19-2.2-1.45.1-2.64,1.19-2.85,2.62l-6.57,43.13c-.03.18-.04.35-.03.53l.79,38.02c-2.19,1.07-4.36,2.17-6.49,3.3v-.02c-.15.08-.29.16-.43.24-.23.12-.45.24-.68.36-.24.14-.45.3-.63.47-.91.64-1.75,1.36-2.42,2.26-.44.88.19,1.82.43,2.32.25.51,1.4.91,2.12.85.71-.06,1.24.38,2.1.05.22-.1.44-.21.66-.31.22-.06.45-.15.68-.26,13.75-7.32,28.49-12.9,43.63-16.38,14.69-3.3,30.25-4.81,45.01-1.87h0c.23.05,1.85.44,2.07.49,1.78.37,2.34.22,4.4-.18.76-.22.32-.95.58-1.1,1.73-.62,2.48-1.99,1.08-3.93ZM254.17,109.49l4.46-29.25,5.63,18.68c.4,1.33,1.64,2.24,3.03,2.2,1.39-.02,2.6-.96,2.95-2.3l6.75-25.44,6.28,23.93-12.45,12.3c-.59.58-.92,1.37-.92,2.2v26.39c0,.08.02.16.02.23-5.1,1.81-10.12,3.84-15.02,6.05l-.73-34.99ZM276.1,136.37v-23.27l20.71-20.46,21.84,16.2v20.86c-14.34-.24-28.71,2.33-42.55,6.67Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
    fontColor: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>

</style>
