<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else-if="isVisible"
                :title="$t('heating-modes-card.title').toString()"
                icon="thermostat_auto">
    <template v-slot:content>
      <active-day-overview :weekdays="weekdays"/>
      <v-list-item dense @click="showHeatingModes">
        <v-list-item-content>
          <v-list-item-title class="primary--text font-weight-bold">{{ $t('heating-modes-card.btn-show-modes') }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon class="material-icons-outlined" color="primary">chevron_right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import ActiveDayOverview from "@/templates/components/ActiveDayOverview";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "HeatingModesCard",

  components: {ContentCard, ActiveDayOverview},

  props: ['gatewayMac'],

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      heatingModes: [],
      weekdays: [[], [], [], [], [], [], []]
    }
  },

  computed: {
    isVisible() {
      return Object.values(this.heatingModes)?.length > 0
    }
  },

  methods: {
    /**
     * opens overview of all heating modes as overlay
     *
     */
    showHeatingModes() {
      this.$root.bisadialog.toggle('heatingModesOverview', true, {heatingModes: this.heatingModes, weekdays: this.weekdays, gatewaymac: this.gatewayMac})
    },
    /**
     * gets heating modes from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'heatingplan/heatingplans'
      }, (response) => {
        this.weekdays = [[], [], [], [], [], [], []]
        this.heatingModes = []
        let respHeatingModes = []
        let timeSchedules = []

        if (response?.data?.data.length > 0) {
          response?.data?.included.forEach((included) => {
            if (included.type === 'timeschedules') {
              timeSchedules.push(included)
            }
          })

          response?.data?.data.forEach((mode) => {
            respHeatingModes.push(mode)
          })

          respHeatingModes = this.adjustHeatingModes(respHeatingModes, timeSchedules)
          respHeatingModes = this.prepareHeatingModes(respHeatingModes)
          this.heatingModes = respHeatingModes

          if (!Object.values(this.heatingModes)?.length > 0) {
            this.$emit('hide-heating-modes-card', true)
          } else {
            this.$emit('hide-heating-modes-card', false)
          }

        } else {
          this.$emit('hide-heating-modes-card', true)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-heating-modes-card', true)
        this.loading = false
      })
    },
    adjustHeatingModes(heatingModes, timeSchedules) {
      heatingModes.forEach((heatingMode, index) => {
        heatingModes[index].includedTimeSchedules = []
        heatingModes[index].devices = []
        heatingMode?.relationships?.timeScheduleList?.data.forEach((includedTimeSchedule) => {
          let timeSchedule = timeSchedules.find(timeSchedule => timeSchedule.id === includedTimeSchedule?.id)
          heatingModes[index].includedTimeSchedules.push(this.prepareTimeScheduleForHeatingMode(timeSchedule))
        })
      })
      return heatingModes
    },
    prepareHeatingModes(heatingModes) {
      let cleanedHeatingModes = []
      heatingModes.forEach((heatingMode) => {
        cleanedHeatingModes.push({
          id: heatingMode.id,
          active: heatingMode?.includedTimeSchedules[0]?.active,
          name: heatingMode?.attributes?.name,
          color: heatingMode?.attributes?.color,
          includedTimeSchedules: heatingMode?.includedTimeSchedules,
          weekdays: heatingMode?.includedTimeSchedules[0]?.weekdays
        })
        this.mapWeekdays(heatingMode?.includedTimeSchedules[0]?.weekdays, heatingMode?.attributes?.color, heatingMode?.includedTimeSchedules[0]?.active)
      })
      return cleanedHeatingModes
    },
    prepareTimeScheduleForHeatingMode(completeTimeSchedule) {
      let cleanedTimeSchedule = {
        id: completeTimeSchedule.id,
        name: completeTimeSchedule?.attributes?.name,
        active: completeTimeSchedule?.attributes?.active,
        hour: completeTimeSchedule?.attributes?.hour,
        minute: completeTimeSchedule?.attributes?.minute,
        seconds: completeTimeSchedule?.attributes?.seconds,
        value: completeTimeSchedule?.attributes?.value,
        weekdays: completeTimeSchedule?.attributes?.weekdays,
        devices: completeTimeSchedule?.attributes?.deviceIds
      }

      return cleanedTimeSchedule
    },
    calcWidth(arr) {
      return 100 / arr.length + '%'
    },

    mapWeekdays(passedWeekdays, color, isActive) {
      passedWeekdays.push(passedWeekdays.shift())
      passedWeekdays.forEach((day, index) => {
        if (day && isActive) {
          this.weekdays[index].push(color)
        }
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
