<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332 332">
    <g transform="translate(0.000000,332.000000) scale(0.100000,-0.100000)"
       :fill="fillColor" stroke="none">
    <path d="M1243 3260 c-35 -14 -48 -45 -98 -235 -157 -598 -163 -620 -170 -613
-4 4 -73 227 -154 503 -23 77 -49 151 -60 165 -36 49 -112 48 -145 -3 -10 -14
-35 -147 -65 -348 -28 -178 -73 -475 -101 -659 l-52 -335 6 -440 c4 -242 9
-512 12 -600 l6 -160 -127 -65 c-69 -36 -144 -82 -167 -104 -35 -34 -39 -43
-34 -70 8 -40 26 -53 84 -61 45 -6 58 -1 262 98 916 446 1833 617 2552 475
118 -23 194 -19 206 10 3 9 16 25 29 35 34 27 31 78 -7 110 -51 44 -199 80
-382 93 l-88 7 -1 346 c0 241 -4 357 -12 381 -10 29 -71 78 -412 332 -220 164
-410 301 -422 304 -42 11 -71 -6 -168 -98 -54 -51 -100 -93 -103 -93 -8 0 -8
2 -142 513 -66 255 -126 472 -132 482 -22 35 -71 48 -115 30z m127 -790 c46
-173 87 -335 92 -359 l10 -44 -198 -198 -199 -199 -5 -431 -5 -431 -75 -28
c-41 -16 -136 -54 -210 -85 -74 -31 -144 -59 -156 -62 l-21 -5 -9 534 c-10
586 -15 507 50 933 9 55 27 179 41 275 14 96 29 183 33 193 5 11 38 -86 95
-275 90 -303 98 -320 154 -334 35 -9 88 18 101 53 10 25 40 135 168 628 38
145 43 160 48 154 2 -2 41 -146 86 -319z m833 -491 c172 -128 322 -241 335
-252 l22 -21 -2 -315 -3 -315 -80 -2 c-427 -13 -788 -71 -1152 -183 -34 -10
-64 -16 -67 -13 -3 3 -6 162 -6 353 l0 347 318 316 c174 174 318 316 320 316
2 0 143 -104 315 -231z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoNoText',
  props: {
    fillColor: {
      type: String,
    },
    fontColor: {
      type: String,
      default: '#000'
    }
  }
}
</script>